import brettBlack from './BrettBlack.png'
import brettSorenson from './BrettSorenson.png'
import brettFace from './BrettFace.png'
import warning from './Warning_icon.svg'
import girls from './clearGirls.jpeg'
import clearLogo from './clearlogo300x380.png'
import './App.css';


function App() {
  return (
    <article className="App">
      <header><h1> Golden City / Oasis Scam in Sumbawa, Indonesia </h1></header>
          <h2>This is a public notice warning potential victims about an investment fraud <br/> in Bali,
          Indonesia under the names <br/><br/><b>The Golden City - Clear World Properties - The Oasis</b></h2>
        <img src={brettFace} className="brett-face" alt="Brett Black's Clear Cafe owner's face" width="25%" style={{marginRight:"20px"}} />
        <img src={clearLogo} className="brett-face" alt="Clear cafe logo" width="20%" />
        <br/>
         <p className="warning">
             <br/> Please be advised that Brett and his team at &nbsp;
             <a href="https://clearcafebali.com" target="_blank" rel="noopener noreferrer">
              Clear Cafe
             </a>
             &nbsp; have already collected over
             <br/><h2><b>$6,000,000 for these projects without delivering anything in 6 years!</b></h2>
        </p>
        <p className="warning">
             The scheme involves fraudulent offers of land in Sumbawa and other Indonesian islands.
         </p>

        <p className="warning">
            Additionally, this person
            <br/><h3><b> Uses a fake name to sign contracts<br/>not legally binding in the Republik of Indonesia</b></h3>
            He has also
            <br/><h3><b> Blocked communication with many of his "investors" <br/> providing no project updates whatsoever</b></h3>
            He is a professional actor and has used his charisma to defraud over 130 people.
        </p>
        <img src={brettBlack} className="brett-black" alt="Brett Black's, Clear Cafe owner's Bali persona" width="30%" />
        <img src={brettSorenson} className="brett-black" alt="Brett Sorenson's Clear Cafe owner's Hollywood persona" width="30%" />

        <br/>
        <a
          className="App-link"
          href="https://www.imdb.com/name/nm1091318/"
          target="_blank"
          rel="noopener noreferrer"
        >
         <h3><b> Brett's IMDB account </b></h3>
        </a>

        <a
          className="App-link"
          href="https://youtu.be/HK50i-sDaH0?t=128"
          target="_blank"
          rel="noopener noreferrer"
        >
         <h3><b> Brett's appearance in Training Day </b></h3>
        </a>

        <br/><p className="warning">
             Dozens of these investors have now
             <h3><b> Filed police reports accusing Brett and his team of fraud and embezzlement</b></h3>
        <p>
            For more information or to join the lawsuit, please reach out to <mark>oasisgcinvestors@gmail.com</mark>
        </p>             <br/>
             <h2><img src={warning} className="brett-black" alt="clear cafe warning sign" width="5%" style={{marginRight: '10px', verticalAlign: 'middle'}} />
              Brett continues to raise money from new investors
              <img src={warning} className="brett-black" alt="clear cafe warning sign" width="5%" style={{marginLeft: '10px', verticalAlign: 'middle'}}  /></h2>
             <h2> We warn anyone considering investing in any of Brett's schemes that </h2>
             <h1> You will get nothing in return! </h1>
        </p>
        <br/>
       <img src={girls} className="brett-black" alt="Brett Black Clear Cafe owner with young Balinese girls" width="40%" />
        <br/><br/><a href="https://gitlab.com/clear-cafe-golden-city-scam/bulletin" target="_blank" rel="noopener noreferrer"> Source code of this website </a>
    </article>
  );
}

export default App;
